import * as React from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import {
  Colors,
  Lang,
  Breakpoint,

  useDimensions,
  getScrollHeight,
  getScrollDirection,

  GlobalHeader,
  Header,
} from '@bluecurrent/web-component-lib';

import languages from '../../../config/languages';
import pages from '../../../config/pages';
import Setup from '../../../config/setup';

import Storage from '../../../services/Storage';

export default function HeaderWrap() {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();
  const scrollHeight = getScrollHeight();
  const scrollDirection = getScrollDirection();

  const [dropdown, setDropdown] = React.useState(false);

  const handleDropdown = (value) => {
    setDropdown(value);
  };

  const renderLanguageList = () => languages.map((lang) => ({
    key: lang.iso,
    title: _(lang.iso, { ns: 'settings' }),
    type: 'radio',
    selected: i18n.language === lang.iso,
    onClick: () => {
      i18n.changeLanguage(lang.iso);
      Storage.setItem('lang', lang.iso);
    },
  }));

  return (
    <>
      <AnimatePresence>
        {
          size.width >= Breakpoint.lg && (
            <motion.div
              style={{
                position: 'fixed',
                width: '100%',
                top:
                  !dropdown
                  && scrollDirection === 'down'
                  && scrollHeight > 45
                    ? -45
                    : 0,
                transition: 'top .2s ease-in',
              }}
              initial={{
                zIndex: 2147483002,
              }}
              animate={{
                zIndex: size.width >= Breakpoint.lg ? 2147483004 : 2147483002,
              }}
              exit={{
                zIndex: 2147483002,
              }}
              transition={{
                delay: size.width >= Breakpoint.lg ? 0.4 : 0,
              }}
            >
              <GlobalHeader
                items={[
                  {
                    id: 0,
                    title: _(i18n.language, { ns: 'settings' }),
                    icon: {
                      name: 'Language',
                      height: 23,
                      width: 23,
                      color: Colors.BLUE,
                      iconSet: 'FA',
                    },
                    dropdownMenu: renderLanguageList(),
                    align: 'left',
                  },
                ]}
                onChangeDropdown={handleDropdown}
              />
            </motion.div>
          )
        }
      </AnimatePresence>
      <div
        style={{
          position: 'fixed',
          zIndex: size.width >= Breakpoint.lg ? 2147483003 : 2147483004,
          top: size.width >= Breakpoint.lg
            ? !dropdown && scrollDirection === 'down' && scrollHeight > 45
              ? 0
              : 45
            : 0,
          width: '100%',
          transition: 'top .2s ease-in',
        }}
      >
        <Header
          items={Setup.SUCCESS ? [] : pages.map(
            ({
              id, title, type, onClick, href, colorScheme, icon, active, header, sideNav,
            }) => (
              {
                id,
                title: Array.isArray(title) ? _(...title) : title,
                type,
                onClick,
                href,
                colorScheme,
                icon,
                active,
                header,
                sideNav,
              }
            ),
          )}
          topButton={{
            title: _(i18n.language, { ns: 'settings' }),
            icon: {
              name: 'Language',
              width: 25,
              height: 25,
              color: Colors.MEDIUM_GREY,
              iconSet: 'FA',
            },
            dropdownMenu: renderLanguageList(),
          }}
        />
      </div>
    </>
  );
}
