import Storage from '../Storage';

const detectLanguage = (i18n) => {
  if (Storage.getItem('lang')) {
    i18n.changeLanguage(Storage.getItem('lang'));
  } else {
    switch (window.location.hostname) {
      case 'investeren.bluecurrent.nl':
        Storage.setItem('lang', 'nl');
        i18n.changeLanguage('nl');
        break;
      case 'invest.bluecurrent.nl':
        Storage.setItem('lang', 'en');
        i18n.changeLanguage('en');
        break;
      default:
        Storage.setItem('lang', 'en');
        i18n.changeLanguage('en');
    }
  }
};

export default detectLanguage;
