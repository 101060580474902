import * as React from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';

import {
  Lang,
  CookiePopup,
} from '@bluecurrent/web-component-lib';

import detectLanguage from '../services/flows/detectLanguage';

import Layout from '../components/Layout';

import Setup from '../config/setup';

import Storage from '../services/Storage';

import {
  GTM_ID,
  pageView,
} from '../lib/gtm';

import '../styles/Global.css';
import '../styles/Components.css';

function MyApp({ Component, pageProps }) {
  const { i18n } = Lang.useTranslation();
  const router = useRouter();

  const [askCookies, setAskCookies] = React.useState(false);

  React.useEffect(() => {
    detectLanguage(i18n);

    if (Setup.PSEUDO && process.env.NODE_ENV === 'development') {
      i18n.changeLanguage('pseudo');
    }
  }, []);

  React.useEffect(() => {
    router.events.on('routeChangeComplete', pageView);

    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events]);

  const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  React.useEffect(() => {
    if (getCookie('cookies') !== 'true') {
      setAskCookies(true);
    }
  }, []);

  const acceptCookies = (accepted) => {
    if (accepted) {
      Storage.setItem('cookies_accepted', 'true');
      Storage.copySessionToLocal();

      /* Set cookie */
      const d = new Date();
      d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
      document.cookie = `cookies=true;expires=${d.toUTCString()};path=/`;
    }
  };

  return (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(...arg){dataLayer.push(...arg)}

            gtag('consent', 'default', {
              ad_storage: 'denied',
              analytics_storage: 'denied',
              functionality_storage: 'denied',
              personalization_storage: 'denied',
              security_storage: 'denied',
              cookieAccepted: 'denied',
              wait_for_update: 500
            });
          `,
        }}
      />
      <Script
        id="GoogleTagManager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
        `,
        }}
      />
      {
        askCookies && (
          <CookiePopup
            showDefault
            callback={acceptCookies}
          />
        )
      }
      <Layout>
        { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default MyApp;
