import * as React from 'react';
import Head from 'next/head';

import {
  Breakpoint,
  Lang,
  Colors,

  useDimensions,
  scrollToId,

  Footer,
  Button,
} from '@bluecurrent/web-component-lib';

import Setup from '../config/setup';

import Header from './Sections/Header';

export default function Layout(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  const title = 'Become co-owner of Blue Current';

  const description = 'Help accelerate the transition towards sustainable mobility';

  const url = 'investeren.bluecurrent.nl';

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0" />
        <title>
          {title}
        </title>
        <meta itemProp="name" content={title} />
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta itemProp="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://${url}`} />
        <meta name="robot" content="index, archive" />
        <meta property="og:image" content="https://cdn.sanity.io/images/pnajo2cq/production/39209bfabe382b81cc26bb1a47b468e6cbe7e84d-1200x630.jpg?q=100" />
        <meta itemProp="image" content="https://cdn.sanity.io/images/pnajo2cq/production/39209bfabe382b81cc26bb1a47b468e6cbe7e84d-1200x630.jpg?q=100" />
      </Head>
      <div
        style={{
          overflow: 'hidden',
        }}
      >
        <Header />
        {
          size.width < Breakpoint.lg && (
            <div
              style={{
                position: 'fixed',
                height: 'auto',
                minHeight: 50,
                width: '100%',
                backgroundColor: Colors.WHITE,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                bottom: 0,
                zIndex: 2147483003,
                padding: 15,
                borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
              }}
            >
              <Button
                id="Button_LayoutReserveLink"
                text={_(
                  Setup.RESERVE
                    ? 'invest.reserveAShare'
                    : Setup.SUCCESS
                      ? 'invest.stayInformed'
                      : 'invest.investNow',
                  { ns: 'branding' },
                )}
                colorScheme={Setup.SUCCESS ? 'blue' : 'green'}
                onClick={(event) => {
                  event.preventDefault();
                  scrollToId({
                    id: 'invest',
                    duration: 1500,
                  });
                }}
              />
            </div>
          )
        }
        <main
          style={{
            marginTop: size.width < 1440 ? 80 : 145,
            marginBottom: 75,
          }}
        >
          {
            props.children
          }
        </main>
        <Footer
          text={_('slogan', { ns: 'branding' })}
          linksBottom={[
            {
              id: 0,
              text: _('privacyPolicy', { ns: 'legal' }),
              href: 'https://www.bluecurrent.nl/legal/privacy-statement',
              target: '_blank',
            },
          ]}
        />
        {
          size.width < Breakpoint.lg && (
            <div
              style={{
                height: 85,
                width: '100%',
                backgroundColor: Colors.GREY,
              }}
            />
          )
        }
      </div>
    </>
  );
}
