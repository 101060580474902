import {
  Colors,

  scrollToId,
} from '@bluecurrent/web-component-lib';

import Setup from './setup';

const scrollDuration = 1500;

export default [
  {
    id: 0,
    title: ['home', { ns: 'ui' }],
    type: 'link',
    sideNav: !Setup.SUCCESS,
    header: false,
    href: '/',
    onClick: () => {},
    colorScheme: 'blue',
    icon: {
      name: 'Minus',
      width: 25,
      height: 25,
      color: Colors.MEDIUM_GREY,
      iconSet: 'FA',
    },
    active: false,
  },
  {
    id: 1,
    title: ['invest.ourMission', { ns: 'branding' }],
    type: 'link',
    sideNav: !Setup.SUCCESS,
    header: !Setup.SUCCESS,
    href: '/#mission',
    onClick: () => {
      scrollToId({
        id: 'mission',
        duration: scrollDuration,
      });
    },
    colorScheme: 'orange',
    icon: {
      name: 'RocketLaunch',
      width: 25,
      height: 25,
      color: Colors.MEDIUM_GREY,
      iconSet: 'FA',
    },
    active: false,
  },
  {
    id: 2,
    title: ['invest.whyInvest', { ns: 'branding' }],
    type: 'link',
    sideNav: !Setup.SUCCESS,
    header: !Setup.SUCCESS,
    href: '/#why',
    onClick: () => {
      scrollToId({
        id: 'why',
        duration: scrollDuration,
      });
    },
    colorScheme: 'green',
    icon: {
      name: 'Leaf',
      width: 25,
      height: 25,
      color: Colors.MEDIUM_GREY,
      iconSet: 'FA',
    },
    active: false,
  },
  {
    id: 3,
    title: ['faq', { ns: 'ui' }],
    type: 'link',
    sideNav: !Setup.SUCCESS,
    header: !Setup.SUCCESS,
    href: '/#faq',
    onClick: () => {
      scrollToId({
        id: 'faq',
        duration: scrollDuration,
      });
    },
    colorScheme: 'yellow',
    icon: {
      name: 'QuestionMarkCircle',
      width: 25,
      height: 25,
      color: Colors.MEDIUM_GREY,
      iconSet: 'FA',
    },
    active: false,
  },
  {
    id: 4,
    title: Setup.RESERVE ? ['invest.reserveAShare', { ns: 'branding' }] : ['invest.investNow', { ns: 'branding' }],
    type: 'button',
    sideNav: !Setup.SUCCESS,
    header: !Setup.SUCCESS,
    href: false,
    onClick: () => {
      scrollToId({
        id: 'invest',
        duration: 1500,
      });
    },
    colorScheme: 'green',
    icon: {
      name: 'HandHoldingSeedling',
      width: 25,
      height: 25,
      color: Colors.MEDIUM_GREY,
      iconSet: 'FA',
    },
    active: true,
  },
];
