class Storage {
  static exemptions = ['token'];
  static itemName = 'bluecurrent.iv-state';

  static getStore() {
    const currentObject = JSON.parse(localStorage.getItem(Storage.itemName)) ?? {};

    return currentObject.cookies_accepted === 'true' ? localStorage : sessionStorage;
  }

  static setItem(key, value) {
    const storage = Storage.getStore();

    const currentObject = JSON.parse(storage.getItem(Storage.itemName)) ?? {};

    currentObject[key] = value;

    storage.setItem(Storage.itemName, JSON.stringify(currentObject));
  }

  static getItem(key) {
    const storage = Storage.getStore();

    const currentObject = JSON.parse(storage.getItem(Storage.itemName)) ?? {};

    return currentObject[key];
  }

  static removeItem(key) {
    const storage = Storage.getStore();

    const currentObject = JSON.parse(storage.getItem(Storage.itemName)) ?? {};

    delete currentObject[key];

    storage.setItem(Storage.itemName, JSON.stringify(currentObject));
  }

  static clear() {
    const storage = Storage.getStore();

    storage.removeItem(Storage.itemName);
    storage.removeItem('token');
  }

  static setOriginalState(key, value) {
    Storage.getStore().setItem(key, value);
  }

  static getOriginalState(key) {
    return Storage.getStore().getItem(key);
  }

  static removeOriginalStateItem(key) {
    Storage.getStore().removeItem(key);
  }

  static copySessionToLocal() {
    const currentObject = sessionStorage.getItem(Storage.itemName);

    localStorage.setItem(Storage.itemName, currentObject);

    Storage.exemptions.forEach((i) => {
      const item = sessionStorage.getItem(i);
      if (item) localStorage.setItem(i, item);
    });

    sessionStorage.clear();
  }
}

export default Storage;
